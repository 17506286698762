import React from 'react';
import styled from 'styled-components';

import mq from '../style/mq';
import { colors } from '../consts/style';

const Wrapper = styled.div`
  max-width: 1600px;
  margin: auto;
  display: flex;
  padding-bottom: 4rem;
  ${mq.tablet`
    flex-wrap: wrap;
  `}
  .comingSoon {
    height: calc(100vh - 320px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      color: ${colors.gold};
    }
  }
`;

export default function Store() {
  return (
    <Wrapper>
      <div className="comingSoon">
        <h1>COMING SOON</h1>
      </div>
    </Wrapper>
  );
}
